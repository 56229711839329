<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="require('@/assets/images/logo/sp_logo_white.png')"
            height="50"
          />

          <h2 class="brand-text text-primary ml-1">
            WELCOME TO
            <br>
            SRIPHAT <span class="text-success">PHR</span>
          </h2>
        </b-link>

        <!-- <b-card-text class="text-center mb-2">
          Please fill in your information to proceed.
        </b-card-text> -->

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- phone -->
            <b-form-group
              label-for="phone"
              label="Phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="userPhone"
                  name="login-phone"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Phone number"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="acceptTermsConditions"
                name="checkbox-1"
                @change="acceptTermsConditionsTemp"
              >
                ข้าพเจ้ายอมรับ
                <b-link v-b-modal.termsAndConditionsModal>
                  ข้อกำหนดและเงื่อนไขการให้บริการนี้
                </b-link>
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>as
          </b-link>
        </b-card-text> -->

        <div
          class="divider my-2"
        >
          <div class="divider-text">
            or
          </div>
        </div>

        <!-- callback-uri="http://localhost:9999/login" -->
        <!-- client-id="1656215228"
        callback-uri="http://phr.sriphat.com/login"
        client-secret="3b2fa9e3a86d5ecd50b7e026ff99444c" -->
        <!-- Local -->
        <!-- client-id="1657248749"
        callback-uri="http://localhost:9999/login"
        client-secret="29f7ec4b91c099f0506a833984b92589" -->
        <div
          class="auth-footer-btn d-flex justify-content-center"
        >
          <!-- Real Server -->
          <vue-line-login
            client-id="1657260303"
            callback-uri="https://phr.sriphat.com/login"
            client-secret="962a5ea2c25fc082152f4ebf7de1dd75"
            login-text="LN"
            @result="LineLogin"
          />
          <!-- Localhost Server -->
          <!-- <vue-line-login
            client-id="1657260303"
            callback-uri="http://localhost:9999/login"
            client-secret="962a5ea2c25fc082152f4ebf7de1dd75"
            login-text="LN"
            @result="LineLogin"
          /> -->
          <!-- <vue-line-login
            client-id="1657015593"
            callback-uri="http://localhost:9999/login"
            client-secret="98a1f1e89b4a105bafb78dc621688314"
            login-text="LN"
            add-friend
            friend-required
            @result="LineLogin"
          /> -->
          <!-- <b-button
            href="javascript:void(0)"
            variant="facebook"อ
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button> -->
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
    <b-modal
      id="termsAndConditionsModal"
      ok-only
      ok-title="Close"
      size="xl"
    >
      <terms-and-conditions />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import VueLineLogin from '@team-decorate/vue-line-login'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import TermsAndConditions from './TermsAndConditions.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BCardText,
    BImg,
    BForm,
    BButton,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
    VueLineLogin,
    TermsAndConditions,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userPhone: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      acceptTermsConditions: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // this.configData()
  },
  methods: {
    configData() {
      // console.log('configData')
      this.acceptTermsConditions = false
      if (localStorage.getItem('acceptTermsConditions')) {
        this.acceptTermsConditions = localStorage.getItem('acceptTermsConditions')
        console.log('Local AcceptTermsConditions', this.acceptTermsConditions)
      }
    },
    LineLogin(res) {
      // console.log('Line', res)
      // const userRole = 'admin'
      const userData = {
        id: 1,
        fullName: res.displayName,
        username: res.userId,
        avatar: res.pictureUrl,
        email: '',
        role: '',
        access_token: res.access_token,
        id_token: res.id_token,
        refresh_token: res.refresh_token,
        userId: res.userId,
        expires_in: res.expires_in,
        ability: [{ action: 'manage', subject: 'all' }],
        extras: { },
      }

      useJwt.setToken(res.access_token)
      useJwt.setRefreshToken(res.access_token)
      localStorage.setItem('userData', JSON.stringify(userData))
      // Check Profile Binding
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/Login',
        method: 'POST',
        data: {
          UserID: res.userId, // Line ID
          Username: '',
          HN: '',
          Password: '',
          TypeLogin: 'LINE',
        },
      }).then(({ data }) => {
        this.checkAccount(data, userData)
      //   let checkBinding = false
      //   // console.log('patientData', data)
      //   this.$ability.update(userData.ability)
      //   if (data.accountID !== 0 && data.customerID !== 0) {
      //     checkBinding = true
      //   }
      //   // Check User Binding in System
      //   if (checkBinding) {
      //     localStorage.setItem('accountData', JSON.stringify(data))
      //     useJwt.setToken(data.token)
      //     useJwt.setRefreshToken(data.token)
      //     this.$router.replace(getHomeRouteForLoggedInUser(userRole))
      //       .then(() => {
      //         this.$toast({
      //           component: ToastificationContent,
      //           position: 'top-right',
      //           props: {
      //             title: `Welcome ${userData.fullName || userData.username}`,
      //             icon: 'CoffeeIcon',
      //             variant: 'success',
      //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
      //           },
      //         })
      //       })
      //       .catch(error => {
      //         this.$refs.loginForm.setErrors(error.response.data.error)
      //       })
      //   } else {
      //     this.$router.replace('/profileBinding')
      //   }
      }).catch(error => {
        // console.log(error)
        this.$refs.loginForm.setErrors(error)
      })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$http({
            url: 'http://phrapi.sriphat.com/api/UserAuthen/Login',
            method: 'POST',
            data: {
              UserID: '',
              Username: this.userPhone,
              HN: '',
              Password: this.password,
              TypeLogin: 'NM',
            },
          }).then(({ data }) => {
            if (data.accountID !== 0 && data.customerID !== 0) {
              // console.log(data)
              const userRole = 'admin'
              const userData = {
                id: 1,
                fullName: data.username,
                username: data.username,
                avatar: '',
                email: '',
                role: userRole,
                ability: [{ action: 'manage', subject: 'all' }],
                extras: { },
              }
              localStorage.setItem('userData', JSON.stringify(userData))
              this.checkAccount(data, userData)
            } else {
              this.$swal({
                title: 'Error!',
                text: ' The phone or password is incorrect',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            // let checkBinding = false
            // // console.log('patientData', data)
            // this.$ability.update(userData.ability)
            // if (data.accountID !== 0 && data.customerID !== 0) {
            //   checkBinding = true
            // }
            // // Check User Binding in System
            // if (checkBinding) {
            //   localStorage.setItem('accountData', JSON.stringify(data))
            //   useJwt.setToken(data.token)
            //   useJwt.setRefreshToken(data.token)
            //   localStorage.setItem('userData', JSON.stringify(userData))
            //   this.$router.replace(getHomeRouteForLoggedInUser(userRole))
            //     .then(() => {
            //       this.$toast({
            //         component: ToastificationContent,
            //         position: 'top-right',
            //         props: {
            //           title: `Welcome ${userData.fullName || userData.username}`,
            //           icon: 'CoffeeIcon',
            //           variant: 'success',
            //           text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //         },
            //       })
            //     })
            //     .catch(error => {
            //       this.$refs.loginForm.setErrors(error.response.data.error)
            //     })
            // } else {
            //   this.$router.replace('/profileCreateAccount')
            // }
          }).catch(error => {
            this.$refs.loginForm.setErrors(error)
          })
        }
      })
    },
    checkAccount(data, userData) {
      // console.log('CheckAccount', data, userData)
      // const userRole = 'Admin'
      let checkBinding = false
      // console.log('patientData', data)
      this.$ability.update(userData.ability)
      if (data.accountID !== 0 && data.customerID !== 0) {
        checkBinding = true
      }
      // Check User Binding in System
      if (checkBinding) {
        localStorage.setItem('accountData', JSON.stringify(data))
        useJwt.setToken(data.token)
        useJwt.setRefreshToken(data.token)
        this.$router.push({ name: 'member-home' })
        // this.$router.push({ name: 'privacy-policy' })
        // this.$router.replace(getHomeRouteForLoggedInUser(userRole))
        //   .then(() => {
        //     this.$toast({
        //       component: ToastificationContent,
        //       position: 'top-right',
        //       props: {
        //         title: `Welcome ${userData.fullName || userData.username}`,
        //         icon: 'CoffeeIcon',
        //         variant: 'success',
        //         text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
        //       },
        //     })
        //   })
        //   .catch(error => {
        //     console.log('checkAccount', error)
        //     // this.$refs.loginForm.setErrors(error.response.data.error)
        //   })
      } else {
        this.$router.replace('/profileCreateAccount')
      }
    },
    acceptTermsConditionsTemp() {
      console.log('Accept', this.acceptTermsConditions)
      localStorage.setItem('acceptTermsConditions', this.acceptTermsConditions)
    },
    loginORG() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: `${this.userPhone}@line.com`,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
